:root {
    --color: #FF8300;
    --orange: #FF8300;
    --gray: #f2f2f2;
    --link: blue;

    --width: 450px;
    --contentWidth: 950px;
    --itemWidth: 370px;
    --margin: 16px 40px 36px 40px;
    --mobileMargin: 1rem;
    --avatarSize: 40px;
}

body {
    margin: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    font-family: 'Muli', sans-serif;
}

h1,h2,h3,h4,h5,button {
    font-family: 'Muli', sans-serif;
}

.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: white;
}

.content-container {
    border: 1px solid var(--color);
    border-radius: 10px;
    width: var(--width);
    overflow-x: hidden;
    overflow-y: auto;
}

.content-wrapper {
    transition: all 250ms;
    width: var(--contentWidth);
    transform: translate3d(0, 0, 0);
}

.content {
    margin: var(--margin);
    float: left;
    width: var(--itemWidth);
}

@media only screen and (max-width: 601px) {
    .content-container {
        border: none;
        width: 100vw;
        height: 100vh;
    }

    .content-wrapper {
        width: calc(200vw + var(--mobileMargin));
        height: 100vh;
    }

    .content {
        margin: .5rem;
        width: calc(100vw - var(--mobileMargin));
    }
}

.input-wrapper {
    position: relative;
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
}

input {
    padding: 13px 15px;
    width: 100%;
    font-size: 1.1rem;
    border-radius: 5px;
    border: 1px solid var(--color);
    background: white !important;
}

input[type=checkbox] {
    width: auto;
    transform: scale(1.3);
}

input:focus {
    outline-color: var(--color);
    outline-radius: 5px;
}

.accept-wrapper {
    margin: -.5rem 0 1rem 0;
    font-size: 1.1rem;
    color: black !important;
}

.input-label {
    transition: transform 100ms, scale 100ms, color 100ms;
    background-color: white;
    position: absolute;
    left: 15px;
    top: 10px;
    padding: .3rem;
    font-size: 1.1rem;
    pointer-events: none;
    line-height: 17px;
}

.input-group {
    display: flex;
    justify-content: space-between;
}

.input-group > :first-child {
    margin-right: .5rem;
}

.input-group > :last-child {
    margin-left: .5rem;
}

.error {
    color: red;
    margin-bottom: .5rem;
}

.info-text {
    color: var(--color);
}

.logo {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
}

.logo > img {
    max-width: 200px;
    max-height: 50px;
}

h1, h2 {
    text-align: center;
}

h1 {
    color: var(--color);
}

h3 {
    margin: .5rem 0;
}

p {
    font-size: 1.05rem;
}

a {
    cursor: pointer;
}

.button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

button {
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    background: var(--color);
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: transform 150ms ease-in-out;
    box-shadow: 1px 1px 2px grey;
    max-height: 35px;
    line-height: 17px;
}

.copy-button {
    background: none;
    box-shadow: none;
    max-height: 100%;
    max-width: 100%;
    color: #000;
    cursor: text;
}
.copy-button:hover {
    transform: none !important;
}
.text-selectable {
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

@media only screen and (max-width: 600px) {
    button {
        line-height: 25px;
        max-height: 45px;
    }
}

button.secondary {
    background: white;
    box-shadow: none;
    border: 1px solid var(--color);
    color: var(--color);
    opacity: .8;
}

button:hover {
    transform: scale(1.01);
}

@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: .6; }
    100% { opacity: 1; }
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
}
button:disabled {
    opacity: .6;
    cursor: progress;
    animation: blink 1s ease-in-out infinite;
}

.no-animation {
    animation: none;
}

.center {
    text-align: center;
    justify-content: center;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.hidden {
    visibility: hidden;
}

.display-none {
    display: none;
}

.app {
    pointer-events: none;
    border: 1px solid transparent;
}

.app.active {
    background: var(--gray);
    border: 1px solid var(--gray);
}

.no-style {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
}

.third-party-header {
    text-align: center;
    margin: 1.5rem 0 1rem 0;
}

.third-party {
    display: flex;
    justify-content: center;
}

.third-party > :first-child {
    margin-right: .5rem;
}

.third-party > :last-child {
    margin-left: .5rem;
}

.google, .microsoft {
    display: flex;
    align-items: center;
    background: white;
    color: #444;
    font-weight: bold;
    width: 125px;
    border-radius: 0px;
    white-space: nowrap;
    padding: 0;
    font-size: 14px;
}

.google svg, .microsoft svg {
    width: 18px;
    height: 18px;
}

.google > :first-child, .microsoft > :first-child {
    background: white;
    display: flex;
    padding: 8px;
    border-radius: 2px;
}

.google > :last-child, .microsoft > :last-child {
    display: inline-block;
    vertical-align: middle;
    font-family: 'Roboto', sans-serif;
    width: 100%;
}

.bankid {
    background: none;
    display: flex;
    border: none;
    padding: 0;
}

.bankid svg {
    width: 125px;
}

.spacer {
    height: 1rem;
}

.busy-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(255, 255, 255, .5);
    animation: blink 1s ease-in-out infinite;
}

.user-list {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.user-item {
    flex: 1;
    display: flex;
    align-items: center;
    border: 1px solid var(--color);
    border-radius: 10px;
    padding: 1rem;
    cursor: pointer;
    margin-bottom: .5rem;
    color: black;
    background: white;
    text-align: left;
    max-height: 80px;
}

.user-item > * {
    pointer-events: none;
}

.padding-top {
    margin-top: 1rem;
}

.user-item:hover {
    background: var(--gray);
}

.user-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: .2;
}

.user-image {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 21px;
    border-radius: 50px;
    color: white;
    background-color: var(--color);
    height: var(--avatarSize);
    width: var(--avatarSize);
    line-height: 17px;
}

.user-image span {
    position: relative;
    top: -2px;
}

.user-info {
    flex: .8;
    display: flex;
    flex-direction: column;
}

.user-name {
    font-size: 1.1rem;
    font-weight: 600;
}

.user-email {
    margin-top: .25rem;
}

.language-switch {
    padding-top: 2rem;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.language-switch select {
    padding: 10px 10px;
    font-size: 1.1rem;
    border-radius: 5px;
    border: 1px solid lightgray;
    background: white !important;
}

li {
    margin-bottom: 0.2rem;
}

.tooltip {
    position: relative;
    display: inline-block;;
    cursor: help;
}

.tooltip .tooltip-info {
    visibility: hidden;
    width: 310px;
    background: var(--gray);
    color: #000;
    box-shadow: 0 1px 3px black;
    text-align: center;
    border-radius: 6px;
    padding: 5px 2px;
    word-break: break-word;
    bottom: 30px;
    right: 0;

    position: absolute;
    z-index: 1;
}

.tooltip:active, .tooltip:focus {
    box-shadow: 0 0 3px #000;
}

.tooltip:active .tooltip-info {
    visibility: visible;
}

.tooltip:focus .tooltip-info {
    visibility: visible;
}

.hover-info {
    display: none;
}